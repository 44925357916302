import React, {
  useCallback,
  useEffect, useState,
} from 'react';
import { T2 } from '../../../styles/titles';



import LoadingOverlay from '../../../components/LoadingOverlay';
import ZeroState from '../../../components/ZeroState';
import {formatDate, formatDateToDateTime} from "../../../utils/formatDate";
import {getAllVendas} from "../../vendas/actions";
import {Pagination} from "antd";
import {getAxiosInstanceLinkPagamento} from "../../../services/providers/api";
import FormFilter from "../../transacoesErepasses/painelDeVendas/components/filter";
import {Button} from "../../transacoesErepasses/painelDeVendas/styles";

import { Container } from './styles';
import LiveTable from "./liveTable";

const ListarAssinaturas: React.FC = () => {
  const selectedBusiness = localStorage.getItem('selectedCompany') ? JSON.parse(localStorage.getItem('selectedCompany') as string) :null;
  const user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo') as string) :null;
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getLinksDePagamento()
  }, []);

  const getLinksDePagamento = async () => {
    const response = await getAxiosInstanceLinkPagamento().get(`link-pagamento/assinatura/listar/${selectedBusiness.merchantId}/${user.id}`);
    setLinks(response.data)
  }



  return (
    <Container>
      <LoadingOverlay
        show={loading}
        relative
      />
      <T2>Assinaturas</T2>
      <LiveTable setLink={setLinks} dataFilter={links}  />
    </Container>
  );
}
export default ListarAssinaturas;
